import { reactive } from "vue";

/**
 * @type {UnwrapNestedRefs<{abilities: {default: []}}>}
 */
const state = reactive({
  abilities: {
    default: []
  },
  abilities_forbidden: {
    default: []
  }
});

/**
 * Init abilities
 * @param abilities
 * @param abilities_forbidden
 * @param entity
 */
export function init(abilities, abilities_forbidden = [], entity = "default") {
  if (Array.isArray(abilities)) {
    state.abilities[entity] = abilities;
  }

  if (Array.isArray(abilities_forbidden)) {
    state.abilities_forbidden[entity] = abilities_forbidden;
  }
}

/**
 * Check user ability
 * @param ability
 * @param entity
 * @returns {boolean}
 */
export function can(ability, entity = "default") {
  const index = state.abilities[entity].findIndex(
    a => a.name === "*" || a.name.toLowerCase() === ability.toLowerCase()
  );

  const forbiddenIndex = state.abilities_forbidden[entity].findIndex(
    a => a.name === "*" || a.name.toLowerCase() === ability.toLowerCase()
  );


  return index !== -1 && forbiddenIndex === -1;
}

/**
 * @returns {{init: init, can: (function(*, *=): boolean)}}
 */
export function useBouncer() {
  return {
    init,
    can
  };
}
