<script>
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { computed, reactive } from "vue";
import { useBouncer } from "@/compositions/useBouncer.js";
import { useErrors } from "@/compositions/useErrors.js";
import Swal from "@/plugins/swal";

export default {
  name: "ManagementSettings",
  setup() {
    const bouncer = useBouncer();

    // Permissions checking
    if(!bouncer.can('team-settings-view')) {
      Swal.fire({
        title: "Warning",
        text: "You do not have permission to view this page!",
        icon: "warning",
        allowOutsideClick: false,
        showConfirmButton: false,
        showCancelButton: false,
      })
    }

    const errors = useErrors();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const state = reactive({
      employees: [],
      team: {},
      loadingLogo: false,
      loading: false
    });

    errors.errorInitContainers(["logo"]);

    store.dispatch("team/getTeam", route.params.id).then(r => {
      state.team = r.data;
    });

    async function updateTeam() {
      errors.errorSet([]);
      state.loading = true;
      try {
        await store.dispatch("team/updateTeam", state.team);
        state.loading = false;
      } catch (e) {
        errors.errorSet(e.errors);
        state.loading = false;
      }
    }

    async function changeLogo(event) {
      errors.errorSet({}, "logo");
      const formData = new FormData();
      formData.append("logo", event.target.files[0]);
      state.loadingLogo = true;
      try {
        const { logo } = await store.dispatch("team/updateLogo", {
          id: state.team.id,
          body: formData
        });
        state.team.logo = logo;
        console.log(logo);
        state.loadingLogo = false;
      } catch (e) {
        errors.errorSet(e.errors, "logo");
        state.loadingLogo = false;
      }
    }

    async function deleteTeam() {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        confirmButtonText: "Yes, delete it!",
        showCancelButton: true,
        cancelButtonText: "Cancel"
      }).then(r => {
        if (r.isConfirmed) {
          store.dispatch("team/deleteTeam", state.team.id).then(() => {
            Swal.fire("Deleted!", "Your team has been deleted.", "success");
            router.push({ name: "dashboard.team.index" });
          });
        }
      });
    }

    return {
      team: computed(() => state.team),
      updateTeam,
      ...errors,
      deleteTeam,
      changeLogo,
      loading: computed(() => state.loading),
      loadingLogo: computed(() => state.loadingLogo)
    };
  }
};
</script>

<template>
  <div class="intro-y box lg:mt-5">
    <div class="p-5">
      <div class="grid grid-cols-12 gap-5">
        <div class="col-span-12 xl:col-span-6">
          <form
            class="space-y-2"
            @submit.prevent="updateTeam"
          >
            <div :class="{ 'has-error': errorHas('name') }">
              <label>Team Name</label>
              <input
                v-model="team.name"
                type="text"
                class="input w-full border placeholder-theme-13 mt-2"
                :disabled="team.project_current_team"
              >
              <span
                v-if="errorHas('name')"
                class="pristine-error text-theme-6 mt-2"
              >{{ errorGet("name") }}.
              </span>
            </div>
            <div
              :class="{
                'has-error': errorHas('description') && !errorHas('teams_count')
              }"
            >
              <label>Description</label>
              <textarea
                v-model="team.description"
                class="input input--lg border w-full pr-10 placeholder-theme-13"
                placeholder="Description"
              />
              <span
                v-if="errorHas('teams_count')"
                class="block pristine-error text-theme-6 mt-2"
              >
                {{ errorGet("teams_count") }}.
              </span>
              <span
                v-else-if="errorHas('description')"
                class="pristine-error text-theme-6 mt-2"
              >{{ errorGet("description") }}.
              </span>
            </div>
            <div
              :class="{
                'has-error':
                  errorHas('logo', 'logo') && !errorHas('teams_count')
              }"
            >
              <label>Logo (Identification icon)</label>
              <div
                class="mt-1 w-40 h-40 relative image-fit cursor-pointer zoom-in"
              >
                <img
                  class="rounded-md"
                  rel="preload"
                  alt="Quant Lines"
                  :src="team.logo"
                >
              </div>
              <div class="w-40 cursor-pointer relative mt-5">
                <base-button
                  variant="w-full bg-blue-700 hover:bg-blue-800 cursor-pointer"
                  size="button--sm"
                  :loading="loadingLogo"
                >
                  Change logo
                </base-button>
                <input
                  ref="profile_photo"
                  type="file"
                  class="w-full h-full top-0 left-0 absolute opacity-0"
                  @change="changeLogo"
                >
                <span
                  v-if="errorHas('logo', 'logo')"
                  class="pristine-error text-theme-6 mt-2"
                >
                  {{ errorGet("logo", "logo") }}
                </span>
              </div>
            </div>
            <div class="flex justify-end pt-5">
              <a
                v-if="!team.personal_team"
                href="#"
                class="text-theme-6 flex items-center"
                @click.prevent="deleteTeam"
              >
                <i class="ri-delete-bin-line" />
                Delete Team
              </a>
              <base-button
                type="submit"
                class="ml-auto"
                :loading="loading"
              >
                Save
              </base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
